import React from 'react';

const Footer = () => (
  <footer style={{ 
    backgroundColor: 'var(--background-color)', 
    padding: '1rem', 
    textAlign: 'center', 
    marginTop: '2rem',
    color: 'var(--light-text-color)',
    fontSize: '0.9rem'
  }}>
    <p>&copy; 2024 PinHome. All rights reserved.</p>
  </footer>
);

export default Footer;