// hooks/useLocationInput.js

import { useState, useCallback } from 'react';

export const useLocationInput = () => {
  const [location, setLocation] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');

  const handleLocationChange = useCallback((e) => {
    setLocation(e.target.value);
  }, []);

  const handleLocationSelect = useCallback((address) => {
    setLocation(address);
    setSelectedLocation(address);
  }, []);

  return {
    location,
    selectedLocation,
    handleLocationChange,
    handleLocationSelect
  };
};