// components/LocationInputs.js

import React from 'react';
import { useLocationInput } from './hooks/useLocationInput';
import { LocationInput } from './LocationInput';

const LocationInputs = ({ onCalculate }) => {
  const {
    location: homeLocation,
    selectedLocation: selectedHomeLocation,
    handleLocationChange: handleHomeLocationChange,
    handleLocationSelect: handleHomeLocationSelect
  } = useLocationInput();

  const {
    location: meetingLocation,
    selectedLocation: selectedMeetingLocation,
    handleLocationChange: handleMeetingLocationChange,
    handleLocationSelect: handleMeetingLocationSelect
  } = useLocationInput();

  const handleCalculate = () => {
    if (selectedHomeLocation && selectedMeetingLocation) {
      onCalculate(selectedHomeLocation, selectedMeetingLocation);
    } else {
      alert('Please select both home and meeting locations.');
    }
  };

  return (
    <>
      <LocationInput
        label="Home Location"
        value={homeLocation}
        onChange={handleHomeLocationChange}
        onSelect={handleHomeLocationSelect}
        placeholder="Enter your home address"
      />
      <LocationInput
        label="Meeting Location"
        value={meetingLocation}
        onChange={handleMeetingLocationChange}
        onSelect={handleMeetingLocationSelect}
        placeholder="Enter meeting location"
      />
      <button 
        onClick={handleCalculate}
        className="calculate-btn"
        style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px', width: '100%' }}
      >
        Calculate Wake-up Time
      </button>
    </>
  );
};

export default LocationInputs;