import React from 'react';
import LocationInputs from './LocationInputs';
import Timeline from './Timeline';

const Dashboard = ({ firstMeeting, setFirstMeeting, onCalculate, isLoading, error, results }) => (
  <div className="dashboard">
    <h2>My Bookings</h2>
    <div className="form-group">
      <label>First Meeting Time:</label>
      <input
        type="time"
        value={firstMeeting}
        onChange={(e) => setFirstMeeting(e.target.value)}
      />
    </div>
    <LocationInputs onCalculate={onCalculate} />
    {isLoading && <p className="loading">Calculating...</p>}
    {error && <p className="error">{error}</p>}
    {results && (
      <div className="results">
        <h3>Results</h3>
        <p><strong>Wake-up Time: {results.wakeUpTime}</strong></p>
        <p>Estimated commute time: {Math.floor(results.commuteDuration / 60)} hours {results.commuteDuration % 60} minutes</p>
      </div>
    )}
    {results && results.timeline && <Timeline timeline={results.timeline} />}
  </div>
);

export default Dashboard;