import React from 'react';

const Header = () => (
  <header style={{
    backgroundColor: 'var(--primary-color)',
    color: 'var(--white)',
    padding: '1rem',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  }}>
    <div style={{
      maxWidth: '1200px',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ marginRight: '0.5rem' }}
        >
          <circle cx="12" cy="12" r="10" />
          <polyline points="12 6 12 12 16 14" />
        </svg>
        <h1 style={{ margin: 0, fontSize: '1.5rem' }}>Wake Up Calculator</h1>
      </div>
    </div>
  </header>
);

export default Header;