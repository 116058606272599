import React, { useState, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const DraggableActivity = ({ id, index, activity, toggleActivity, moveActivity, adjustActivityTime }) => {
  const ref = useRef(null);
  
  const [{ isDragging }, drag] = useDrag({
    type: 'activity',
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'activity',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveActivity(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div 
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        padding: '10px',
        margin: '0 0 8px 0',
        backgroundColor: activity.checked ? 'var(--primary-color)' : 'var(--white)',
        color: activity.checked ? 'var(--white)' : 'var(--text-color)',
        borderRadius: 'var(--border-radius)',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="checkbox"
          checked={activity.checked}
          onChange={() => toggleActivity(activity.id)}
          style={{ marginRight: '10px' }}
        />
        <span>{activity.name}</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="number"
          value={activity.duration}
          onChange={(e) => adjustActivityTime(activity.id, parseInt(e.target.value, 10))}
          min="1"
          max="120"
          style={{ 
            width: '40px', 
            marginRight: '5px',
            padding: '4px',
            borderRadius: '4px',
            border: '1px solid var(--light-gray)'
          }}
        />
        <span>min</span>
      </div>
    </div>
  );
};

const DraggableActivities = ({ activities, toggleActivity, moveActivity, adjustActivityTime }) => {
  const [visibleActivities, setVisibleActivities] = useState(6);

  const loadMore = () => {
    setVisibleActivities(prev => Math.min(prev + 3, activities.length));
  };

  return (
    <div style={{ maxHeight: '400px', overflowY: 'auto', paddingRight: '10px' }}>
      {activities.slice(0, visibleActivities).map((activity, index) => (
        <DraggableActivity
          key={activity.id}
          id={activity.id}
          index={index}
          activity={activity}
          toggleActivity={toggleActivity}
          moveActivity={moveActivity}
          adjustActivityTime={adjustActivityTime}
        />
      ))}
      {visibleActivities < activities.length && (
        <button 
          onClick={loadMore}
          style={{
            width: '100%',
            padding: '10px',
            marginTop: '10px',
            backgroundColor: 'white',
            color: 'var(--primary-color)',
            borderRadius: 'var(--border-radius)',
            cursor: 'pointer',
            fontSize: '16px',
            border: '1px solid var(--primary-color)'
          }}
        >
          Load More
        </button>
      )}
    </div>
  );
};

export default DraggableActivities;