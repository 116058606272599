import { useState, useEffect } from 'react';

const REACT_APP_GOOGLE_MAPS_API_KEY = 'AIzaSyDxV9QehRYZAil9bpIFwr4-j3n250dmtDI';

export const useGoogleMapsLoader = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&language=en&region=US&callback=initMap`;
      script.async = true;
      script.defer = true;
      
      window.initMap = () => {
        setIsLoaded(true);
      };

      script.onerror = (error) => {
        console.error('Google Maps script loading error:', error);
        setError(error);
      };

      document.head.appendChild(script);

      return () => {
        window.initMap = null;
      };
    } else if (window.google && window.google.maps) {
      setIsLoaded(true);
    }
  }, []);

  return { isLoaded, error };
};