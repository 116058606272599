const API_URL = 'https://wake-up-backend-cf8e56d46e69.herokuapp.com';

export const calculateSchedule = async (firstMeeting, activities, homeLocation, meetingLocation) => {
  if (!homeLocation || !meetingLocation) {
    throw new Error('Please select both home and meeting locations from the suggestions.');
  }

  const response = await fetch(`${API_URL}/api/calculate-commute`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ origin: homeLocation, destination: meetingLocation }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error || 'Failed to calculate commute time');
  }

  const commuteDuration = Math.ceil(data.duration);
  const wakeUpTime = calculateWakeUpTime(firstMeeting, commuteDuration, activities);
  const timeline = generateTimeline(wakeUpTime, activities, firstMeeting, commuteDuration);

  return { wakeUpTime, commuteDuration, timeline };
};

const calculateWakeUpTime = (firstMeeting, commuteDuration, activities) => {
  const meetingTime = new Date(`2000-01-01T${firstMeeting}`);
  let totalDuration = commuteDuration;

  activities.forEach(activity => {
    if (activity.checked) {
      totalDuration += activity.duration;
    }
  });

  const wakeUpDateTime = new Date(meetingTime.getTime() - totalDuration * 60000);
  return wakeUpDateTime.toTimeString().slice(0, 5);
};

const generateTimeline = (wakeUpTime, activities, meetingTime, commuteDuration) => {
    let currentTime = new Date(`2000-01-01T${wakeUpTime}`);
    const timeline = activities.filter(activity => activity.checked).map(activity => {
      const startTime = new Date(currentTime);
      currentTime.setMinutes(currentTime.getMinutes() + activity.duration);
      return {
        ...activity,
        time: startTime.toTimeString().slice(0, 5),
        endTime: currentTime.toTimeString().slice(0, 5),
        description: activity.description || `Duration: ${activity.duration} minutes`
      };
    });

  // Add commute
  const commuteStartTime = new Date(currentTime);
  currentTime.setMinutes(currentTime.getMinutes() + commuteDuration);
  timeline.push({
    id: 'commute',
    name: 'Commute',
    duration: commuteDuration,
    checked: true,
    time: commuteStartTime.toTimeString().slice(0, 5),
    endTime: currentTime.toTimeString().slice(0, 5),
    description: `Estimated travel time: ${Math.floor(commuteDuration / 60)} hours ${commuteDuration % 60} minutes`
  });

  // Add meeting
  const meetingStartTime = new Date(`2000-01-01T${meetingTime}`);
  const meetingEndTime = new Date(meetingStartTime.getTime() + 60 * 60000); // Add 1 hour (60 minutes)
  timeline.push({
    id: 'meeting',
    name: 'Meeting',
    duration: 60, // Assume 1-hour meeting
    checked: true,
    time: meetingTime,
    endTime: meetingEndTime.toTimeString().slice(0, 5),
    description: 'First Meeting'
  });

  return timeline;
};

 

  // Add meeting



