import React from 'react';
import Header from './components/Header';
import WakeUpCalculator from './components/WakeUpCalculator';
import Footer from './components/Footer';
import './styles.css';

function App() {
  return (
    <div className="App" style={{ 
      backgroundColor: 'var(--background-color)', 
      minHeight: '100vh', 
      display: 'flex', 
      flexDirection: 'column',
      overflow: 'hidden' // Prevent any potential overflow
    }}>
      <Header />
      <main className="container">
        <WakeUpCalculator />
      </main>
      <Footer />
    </div>
  );
}

export default App;