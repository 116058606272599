import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useGoogleMapsLoader } from './hooks/useGoogleMapsLoader';
import Dashboard from './Dashboard';
import ActivitiesPanel from './ActivitiesPanel';
import { calculateSchedule } from './utils/scheduleCalculator';

const WakeUpCalculator = () => {
  const [firstMeeting, setFirstMeeting] = useState('09:00');
  const [activities, setActivities] = useState([
    { "id": 1, "name": "Make the bed", "duration": 5, "checked": false },
    { "id": 2, "name": "Stretch", "duration": 5, "checked": false },
    { "id": 3, "name": "Take vitamins/medications", "duration": 5, "checked": false },
    { "id": 4, "name": "Prepare and drink coffee/tea", "duration": 10, "checked": false },
    { "id": 5, "name": "Brush teeth", "duration": 5, "checked": false },
    { "id": 6, "name": "Meditate", "duration": 10, "checked": false },
    { "id": 7, "name": "Skin care routine", "duration": 10, "checked": false },
    { "id": 8, "name": "Shave", "duration": 10, "checked": false },
    { "id": 9, "name": "Choose outfit", "duration": 10, "checked": false },
    { "id": 10, "name": "Take a shower", "duration": 15, "checked": true },
    { "id": 11, "name": "Iron clothes", "duration": 10, "checked": false },
    { "id": 12, "name": "Tidy up living space", "duration": 10, "checked": false },
    { "id": 13, "name": "Journal or plan the day", "duration": 10, "checked": false },
    { "id": 14, "name": "Call a family member", "duration": 10, "checked": false },
    { "id": 15, "name": "Water plants", "duration": 5, "checked": false },
    { "id": 16, "name": "Charge devices", "duration": 5, "checked": false },
    { "id": 17, "name": "Prepare smoothie", "duration": 10, "checked": false },
    { "id": 18, "name": "Eat breakfast", "duration": 10, "checked": false },
    { "id": 19, "name": "Cook breakfast", "duration": 20, "checked": true },
    { "id": 20, "name": "Feed pets", "duration": 5, "checked": false },
    { "id": 21, "name": "Walk the dog", "duration": 20, "checked": false },
    { "id": 22, "name": "Do yoga", "duration": 20, "checked": false },
    { "id": 23, "name": "Check and respond to emails", "duration": 15, "checked": false },
    { "id": 24, "name": "Listen to a podcast", "duration": 15, "checked": false },
    { "id": 25, "name": "Practice a musical instrument", "duration": 15, "checked": false },
    { "id": 26, "name": "Prepare lunch to take to work", "duration": 15, "checked": false },
    { "id": 27, "name": "Review work/study materials", "duration": 20, "checked": false },
    { "id": 28, "name": "Read news or a book", "duration": 15, "checked": false },
    { "id": 29, "name": "Pack gym bag", "duration": 5, "checked": false },
    { "id": 30, "name": "Pack work bag", "duration": 5, "checked": false },
    { "id": 31, "name": "Check weather", "duration": 5, "checked": false },
    { "id": 32, "name": "Take out trash", "duration": 5, "checked": false },
    { "id": 33, "name": "Do morning exercises", "duration": 30, "checked": true }
  ]
);
  const [calculationResults, setCalculationResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const { isLoaded, error: mapsError } = useGoogleMapsLoader();

  const handleCalculate = async (homeLocation, meetingLocation, retries = 3) => {
    setIsLoading(true);
    setError('');
    
    for (let i = 0; i < retries; i++) {
      try {
        console.log(`Attempt ${i + 1} to calculate schedule`);
        const results = await calculateSchedule(firstMeeting, activities, homeLocation, meetingLocation);
        setCalculationResults(results);
        setIsLoading(false);
        return; // Success, exit the function
      } catch (err) {
        console.error(`Attempt ${i + 1} failed:`, err);
        if (i === retries - 1) {
          setError(`Error: ${err.message}. Please check your network connection and try again.`);
        }
      }
    }
    
    setIsLoading(false);
  };

  if (!isLoaded) return <div className="loading-message">Loading Google Maps...</div>;
  if (mapsError) return <div className="error-message">Error loading Google Maps. Please try again later.</div>;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="calculator">
        <Dashboard
          firstMeeting={firstMeeting}
          setFirstMeeting={setFirstMeeting}
          onCalculate={handleCalculate}
          isLoading={isLoading}
          error={error}
          results={calculationResults}
        />
        <ActivitiesPanel
          activities={activities}
          setActivities={setActivities}
        />
      </div>
    </DndProvider>
  );
};

export default WakeUpCalculator;