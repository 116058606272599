import React, { useCallback, useState, useEffect } from 'react';
import DraggableActivities from './DraggableActivities';

const ActivitiesPanel = ({ activities: initialActivities, setActivities }) => {
  const [isAddingActivity, setIsAddingActivity] = useState(false);
  const [newActivityName, setNewActivityName] = useState('');
  const [sortedActivities, setSortedActivities] = useState([]);

  const sortActivities = useCallback((activitiesToSort) => {
    return [...activitiesToSort].sort((a, b) => {
      if (a.checked === b.checked) return 0;
      return a.checked ? -1 : 1;
    });
  }, []);

  useEffect(() => {
    setSortedActivities(sortActivities(initialActivities));
  }, [initialActivities, sortActivities]);

  const toggleActivity = useCallback((id) => {
    setSortedActivities(prevActivities => {
      const newActivities = prevActivities.map(activity =>
        activity.id === id ? { ...activity, checked: !activity.checked } : activity
      );
      const sorted = sortActivities(newActivities);
      setActivities(sorted);
      return sorted;
    });
  }, [setActivities, sortActivities]);

  const moveActivity = useCallback((dragIndex, hoverIndex) => {
    setSortedActivities(prevActivities => {
      const newActivities = [...prevActivities];
      const draggedActivity = newActivities[dragIndex];
      newActivities.splice(dragIndex, 1);
      newActivities.splice(hoverIndex, 0, draggedActivity);
      const sorted = sortActivities(newActivities);
      setActivities(sorted);
      return sorted;
    });
  }, [setActivities, sortActivities]);

  const adjustActivityTime = useCallback((id, newDuration) => {
    setSortedActivities(prevActivities => {
      const newActivities = prevActivities.map(activity =>
        activity.id === id ? { ...activity, duration: newDuration } : activity
      );
      const sorted = sortActivities(newActivities);
      setActivities(sorted);
      return sorted;
    });
  }, [setActivities, sortActivities]);

  const addNewActivity = useCallback(() => {
    if (newActivityName.trim()) {
      setSortedActivities(prevActivities => {
        const newActivities = [
          ...prevActivities,
          {
            id: Date.now(),
            name: newActivityName.trim(),
            duration: 15,
            checked: true
          }
        ];
        const sorted = sortActivities(newActivities);
        setActivities(sorted);
        return sorted;
      });
      setNewActivityName('');
      setIsAddingActivity(false);
    }
  }, [newActivityName, setActivities, sortActivities]);

  return (
    <div className="activities-panel">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <h3 style={{ margin: 0 }}>Morning Activities</h3>
        <button 
          className="add-activity-button" 
          onClick={() => setIsAddingActivity(!isAddingActivity)}
          style={{
            backgroundColor: 'var(--primary-color)',
            color: 'var(--white)',
            border: 'none',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            fontSize: '20px',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'transform 0.3s ease'
          }}
        >
          {isAddingActivity ? '−' : '+'}
        </button>
      </div>
      <div 
        className="add-activity-form-container"
        style={{
          maxHeight: isAddingActivity ? '100px' : '0',
          overflow: 'hidden',
          transition: 'max-height 0.3s ease, opacity 0.3s ease',
          opacity: isAddingActivity ? 1 : 0,
          marginBottom: isAddingActivity ? '1rem' : '0'
        }}
      >
        <div className="add-activity-form" style={{ display: 'flex', marginTop: '0.5rem' }}>
          <input
            type="text"
            value={newActivityName}
            onChange={(e) => setNewActivityName(e.target.value)}
            placeholder="Activity name"
            style={{ flex: 1, marginRight: '0.5rem', padding: '0.5rem' }}
          />
          <button 
            onClick={addNewActivity} 
            style={{
              width: '60px',
              borderRadius: '10px',
              border: '1px #e0e0e0 solid', 
              cursor: 'pointer',
              padding: '0.5rem'
            }}
          >
            Add
          </button>
        </div>
      </div>
      <DraggableActivities 
        activities={sortedActivities} 
        toggleActivity={toggleActivity}
        moveActivity={moveActivity}
        adjustActivityTime={adjustActivityTime}
      />
    </div>
  );
};

export default ActivitiesPanel;