import React from 'react';

const TimelineItem = ({ activity, isLast, isCommute, isWakeUp }) => {
  const isRoutine = !isWakeUp && !isCommute && activity.name !== 'Meeting';

  return (
    <div className="timeline-item" style={{ 
        display: 'flex', 
        marginBottom: isLast ? 0 : '15px', 
        position: 'relative',
      }}>
        <div className="timeline-time" style={{
          width: '40px',
          paddingRight: '10px',
          textAlign: 'right',
          fontSize: '14px',
          color: '#666',
          paddingTop: '12px',
          fontWeight: isWakeUp ? 'bold' : 'normal',
        }}>
          {activity.time}
        </div>
        <div className="timeline-content" style={{
          position: 'relative',
          paddingLeft: '20px',
          flex: 1,
        }}>
        <div className="timeline-marker" style={{
          position: 'absolute',
          left: '0',
          top: '8px',
          width: '16px',
          height: '16px',
          borderRadius: '50%',
          backgroundColor: activity.name === 'Meeting' ? '#007bff' : (isWakeUp ? '#28a745' : '#e0e0e0'),
          zIndex: 2,
        }}/>
        {!isLast && (
          <div className="timeline-line" style={{
            position: 'absolute',
            left: '7px',
            top: '24px',
            bottom: isCommute ? '-20px' : '-20px',
            width: '2px',
            backgroundColor: isWakeUp ? '#28a745' : '#e0e0e0',
            zIndex: 1,
          }}/>
        )}
        <div style={{
          backgroundColor: activity.name === 'Meeting' ? '#007bff' : (isCommute ? '#f8f9fa' : 'transparent'),
          borderRadius: isCommute ? '8px 8px 0 0' : '8px',
          padding: isRoutine || isWakeUp ? '5px 0' : (isCommute ? '9px 9px 15px 9px' : (isLast? '9px' : '15px')),
          width: 'calc(100% - 16px)',
          boxShadow: isRoutine || isWakeUp ? 'none' : '0 2px 4px rgba(0,0,0,0.1)',
          marginBottom: isCommute ? '-20px' : '10px',
          marginLeft: '5px'
        }}>
          {isWakeUp && (
            <div style={{
              color: '#28a745',
              fontWeight: 'bold',
              marginBottom: '5px',
              fontSize: '16px',
            }}>
              Wake Up Time
            </div>
          )}
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '5px',
          }}>
            <h3 style={{
              margin: 0,
              color: activity.name === 'Meeting' ? '#fff' : '#333',
              fontSize: isRoutine || isWakeUp ? '16px' : '18px',
              fontWeight: 'normal',
            }}>{activity.name}</h3>
          </div>
          <p style={{
            margin: 0,
            color: activity.name === 'Meeting' ? '#fff' : '#666',
            fontSize: isRoutine ? '12px' : '14px',
          }}>{activity.description}</p>
        </div>
      </div>
    </div>
  );
};

const Timeline = ({ timeline }) => {
  const wakeUpTime = timeline[0]; // Assuming the first item is the wake-up time
  const commute = timeline.find(item => item.name === 'Commute');
  const meeting = timeline.find(item => item.name === 'Meeting');
  const otherActivities = timeline.filter(item => item.name !== 'Commute' && item.name !== 'Meeting' && item !== wakeUpTime);

  return (
    <div className="timeline" style={{ marginTop: '30px' }}>
      <h3 style={{ marginBottom: '20px', color: '#333', fontSize: '20px' }}>Your Day Timeline</h3>
      <div>
        <TimelineItem 
          key={wakeUpTime.id} 
          activity={wakeUpTime} 
          isLast={false}
          isWakeUp={true}
        />
        {otherActivities.map((activity, index) => (
          <TimelineItem 
            key={activity.id} 
            activity={activity} 
            isLast={index === otherActivities.length - 1 && !commute && !meeting}
          />
        ))}
        {commute && (
          <TimelineItem 
            activity={commute} 
            isCommute={true}
            isLast={!meeting}
          />
        )}
        {meeting && (
          <TimelineItem 
            activity={meeting} 
            isLast={true}
          />
        )}
      </div>
    </div>
  );
};

export default Timeline;